/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AuthenticationLoginPageLoginMutationVariables = {
    username: string;
    password: string;
    rememberMe?: boolean | null | undefined;
    deviceName: string;
    requestAccessToken: boolean;
};
export type AuthenticationLoginPageLoginMutationResponse = {
    readonly login: {
        readonly message: string | null;
        readonly result: ({
            readonly __typename: "LoggedIn";
            readonly accessToken?: string | undefined;
            readonly user: {
                readonly __typename: string;
                readonly id: string;
                readonly email: string;
                readonly language: string;
                readonly " $fragmentRefs": FragmentRefs<"NavigationBar_user">;
            };
        } | {
            readonly __typename: "PasswordExpired";
            readonly daysValid: number;
            readonly email: string;
            readonly token: string;
        }) | null;
    };
};
export type AuthenticationLoginPageLoginMutation = {
    readonly response: AuthenticationLoginPageLoginMutationResponse;
    readonly variables: AuthenticationLoginPageLoginMutationVariables;
};



/*
mutation AuthenticationLoginPageLoginMutation(
  $username: String!
  $password: String!
  $rememberMe: Boolean
  $deviceName: String!
  $requestAccessToken: Boolean!
) {
  login(username: $username, password: $password, rememberMe: $rememberMe) {
    message
    result {
      __typename
      ... on LoggedIn {
        accessToken(deviceName: $deviceName) @include(if: $requestAccessToken)
        user {
          __typename
          id
          email
          language
          ...NavigationBar_user
        }
      }
      ... on PasswordExpired {
        daysValid
        email
        token
      }
    }
  }
}

fragment NavigationBar_user on AuthenticatedUser {
  hasDuellingAvailable
  hasDuellingEnabled
  isOnboarding
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "deviceName"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "password"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "rememberMe"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "requestAccessToken"
    } as any, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "username"
    } as any, v5 = [
        {
            "kind": "Variable",
            "name": "password",
            "variableName": "password"
        } as any,
        {
            "kind": "Variable",
            "name": "rememberMe",
            "variableName": "rememberMe"
        } as any,
        {
            "kind": "Variable",
            "name": "username",
            "variableName": "username"
        } as any
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
    } as any, v11 = {
        "condition": "requestAccessToken",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "deviceName",
                        "variableName": "deviceName"
                    }
                ],
                "kind": "ScalarField",
                "name": "accessToken",
                "storageKey": null
            }
        ]
    } as any, v12 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "daysValid",
                "storageKey": null
            },
            (v9 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
            }
        ],
        "type": "PasswordExpired",
        "abstractKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthenticationLoginPageLoginMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "LoginPayloadResult",
                    "kind": "LinkedField",
                    "name": "login",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AuthenticatedUser",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v7 /*: any*/),
                                                (v8 /*: any*/),
                                                (v9 /*: any*/),
                                                (v10 /*: any*/),
                                                {
                                                    "args": null,
                                                    "kind": "FragmentSpread",
                                                    "name": "NavigationBar_user"
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v11 /*: any*/)
                                    ],
                                    "type": "LoggedIn",
                                    "abstractKey": null
                                },
                                (v12 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v4 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AuthenticationLoginPageLoginMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "LoginPayloadResult",
                    "kind": "LinkedField",
                    "name": "login",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v7 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AuthenticatedUser",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v7 /*: any*/),
                                                (v8 /*: any*/),
                                                (v9 /*: any*/),
                                                (v10 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasDuellingAvailable",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasDuellingEnabled",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "isOnboarding",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v11 /*: any*/)
                                    ],
                                    "type": "LoggedIn",
                                    "abstractKey": null
                                },
                                (v12 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d59d0486e77fb47f1637aa4cf22ae4d7",
            "id": null,
            "metadata": {},
            "name": "AuthenticationLoginPageLoginMutation",
            "operationKind": "mutation",
            "text": "mutation AuthenticationLoginPageLoginMutation(\n  $username: String!\n  $password: String!\n  $rememberMe: Boolean\n  $deviceName: String!\n  $requestAccessToken: Boolean!\n) {\n  login(username: $username, password: $password, rememberMe: $rememberMe) {\n    message\n    result {\n      __typename\n      ... on LoggedIn {\n        accessToken(deviceName: $deviceName) @include(if: $requestAccessToken)\n        user {\n          __typename\n          id\n          email\n          language\n          ...NavigationBar_user\n        }\n      }\n      ... on PasswordExpired {\n        daysValid\n        email\n        token\n      }\n    }\n  }\n}\n\nfragment NavigationBar_user on AuthenticatedUser {\n  hasDuellingAvailable\n  hasDuellingEnabled\n  isOnboarding\n}\n"
        }
    } as any;
})();
(node as any).hash = '987e59bd08cf86d6564ea2aea95ec033';
export default node;
