/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RoutingAppRoutes_user = {
    readonly isOnboarding: boolean;
    readonly " $fragmentRefs": FragmentRefs<"RoutingOnlyGuests_user" | "RoutingRequireAuthentication_user">;
    readonly " $refType": "RoutingAppRoutes_user";
};
export type RoutingAppRoutes_user$data = RoutingAppRoutes_user;
export type RoutingAppRoutes_user$key = {
    readonly " $data"?: RoutingAppRoutes_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RoutingAppRoutes_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutingAppRoutes_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnboarding",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutingOnlyGuests_user"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutingRequireAuthentication_user"
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = '8c3b845d09dfcd5e0e70d69eab7bba6c';
export default node;
