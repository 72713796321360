/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RoutingRequireAuthentication_user = {
    readonly isOnboarding: boolean;
    readonly " $refType": "RoutingRequireAuthentication_user";
};
export type RoutingRequireAuthentication_user$data = RoutingRequireAuthentication_user;
export type RoutingRequireAuthentication_user$key = {
    readonly " $data"?: RoutingRequireAuthentication_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RoutingRequireAuthentication_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutingRequireAuthentication_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnboarding",
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = '92d12408f088ac8a9b943900e8641550';
export default node;
