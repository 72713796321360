import { Translations } from './types'

const translations: Translations = {
  popup: {
    confirm: {
      delete: 'Verwijderen',
      keep: 'Behouden',
      cancelDuelInviteTitle: 'Duel uitnodiging verwijderen',
      cancelDuelInviteMessage:
        'Weet je zeker dat je de uitnodiging wilt verwijderen?',
    },
    comeBackLater: {
      heading: 'Kom later terug',
      explanation1:
        'Je hebt alle voor jou op dit moment beschikbare items afgerond. Kom later terug om verder te gaan met je onboarding.',
      explanation2: 'Jouw volgende update komt beschikbaar op:',
      buttonText: 'Naar mijn overzicht',
    },
    finishedOnboarding: {
      proceedHeading: 'Continu leren in de PowerApp',
      stayHeading: 'OnFire onboarding afgerond',
      proceedExplanation1:
        'Gefeliciteerd! Je hebt jouw onboarding in OnFire afgerond. Je mag nu door naar de PowerApp, dé plek waar we in onze organisatie continu leren.',
      proceedExplanation2:
        'Wil je nu doorgaan naar de PowerApp of nog even in OnFire blijven? Je kan altijd doorgaan vanuit het hoofdmenu.',
      stayExplanation:
        'Gefeliciteerd! Je hebt jouw onboarding in OnFire afgerond.',
      proceedToPowerAppButtonText: 'Naar de PowerApp',
      stayInOnfireButtonText: 'Blijf in OnFire',
      closeButtonText: 'Sluiten',
    },
    privacyPolicy: {
      heading: 'Privacybeleid',
      explanation1:
        'Door deze app te gebruiken ga je akkoord met ons privacybeleid.',
      buttonText: 'Accepteren',
    },
    repetitionStateInfo: {
      heading: 'Oefen indicator',
      explanation: {
        general:
          'Op de oefenindicator lees je af of het tijd is om te oefenen. De indicator heeft drie statussen, vergelijkbaar met een stoplicht.',
        alert: 'Rood: Je kennis is weggezakt. Het is tijd om te oefenen.',
        warning:
          'Oranje: Er staan nog vragen voor je klaar. Zodra de kleur op groen gaat is jouw kennis up‑to‑date.',
        complete:
          'Groen: Perfect, je bent helemaal up‑to‑date! Keer op een later moment weer terug.',
        sufficient: 'Groen: Perfect, je bent voldoende up‑to‑date!.',
      },
    },
  },
  header: {
    'welcome-message': 'Hoi {{name}}!',
    welcome: 'Welkom.',
    welcomeBack: 'Welkom terug.',
  },
  learn: {
    topicCategorySelectionIndicator: 'Thema {{current}}/{{countFormatted}}',
    topicCategorySelection: {
      a11y: {
        previousButtonLabel: 'Vorige thema',
        nextButtonLabel: 'Volgende thema',
      },
    },
    instruction: 'Selecteer de topics die je wilt oefenen.',
    practiceSelection: 'Oefen deze selectie',
    practiceAll: "Oefen alle thema's",
    practiceAllNecessary: {
      messageTargetGreenStatus:
        '<p><b>Wil jij jouw kennis snel up‑to‑date krijgen?</b></p><p>Beantwoord deze reeks aan vragen om zo snel mogelijk de groene status te bereiken.</p>',
      messageTargetOverallUpToDateStatus:
        '<p><b>Wil jij jouw kennis snel volledig up‑to‑date krijgen?</b></p><p>Beantwoord dan deze reeks aan vragen om dat zo snel mogelijk te bereiken.</p>',
      messageTargetSelection:
        '<p><b>Wil jij jouw kennis snel up‑to‑date krijgen?</b></p><p>Selecteer topics en start met oefenen.</p>',
      messageOverallUpToDate:
        '<p><b>Goed bezig!</b></p><p>Wil je up‑to‑date blijven? Oefen dan verder!</p>',
      practiceTopics: 'Start voorgeselecteerde reeks',
    },
    selectAll: 'Selecteer alles',
    deselectAll: 'Verwijder selectie',
    wellDone: 'Goed gedaan!',
    anotherStepForwardKnowledge: 'Weer een stapje vooruit!',
    anotherStepForwardSubjective:
      'Je bent je steeds meer bewust over dit onderwerp!',
    instructionLinear:
      'Selecteer een specifiek moment waar je meer informatie van wil zien',
    instructionTimeline:
      'Selecteer een specifieke fase waar je meer informatie van wil zien',
    endOfTimeline: 'Einde van tijdlijn',
    switchToPowerApp: 'Ga verder met continu leren in de PowerApp',
    congratsFinished: 'Gefeliciteerd, je bent klaar!',
    toThePowerapp: 'Naar de PowerApp',
    availableFrom: 'Beschikbaar vanaf',
    available: 'Beschikbaar',
    availabilityUnknown: 'Nog niet bekend',
    startedAt: 'Gestart:',
    completedAt: 'Afgerond:',
    percentageUpToDate: '<0>{{count}}%</0> up‑to‑date',
    topicCount: '{{countFormatted}} topic',
    topicCount_plural: '{{countFormatted}} topics',
    knowledge: 'Kennis',
    awareness: 'Bewustwording',
    poll: 'Peiling',
    topics: {
      toDo: 'To do',
      upToDate: 'Up‑to‑date',
      practiceCheckbox: 'Oefenen',
      extraPracticeButton: 'Extra oefenen',
    },
    repetitionState: {
      header: {
        complete: 'Perfect, je bent helemaal up‑to‑date!',
        sufficient: 'Perfect, je bent voldoende up‑to‑date!',
        warning: 'Start nu om up‑to‑date te blijven.',
        alert: 'Het is tijd om te oefenen.',
        alertFirstLogin: 'Je kunt beginnen met oefenen.',
        showScore: 'Jouw totale score: {{score}}%',
      },
      practiceIndicator: {
        a11y: {
          title: 'Oefenindicator',
          statusMessage: 'De status is {{statusColor}}',
          buttonPurpose: 'Bekijk uitleg over de oefenindicator.',
          statusColor: {
            green: 'groen',
            orange: 'oranje',
            red: 'rood',
          },
        },
      },
    },
    streamBatchProgress: {
      message: {
        batchCompleted: 'Je bent up‑to‑date',
        upToDate: 'Je bent al up‑to‑date.',
        nrToUpToDate: 'Nog {{nr}} te gaan',
      },
    },
    questionStatusLabel: {
      repeatQuestion: 'Herhaling',
      newQuestion: 'Nieuw!',
    },
  },
  duels: {
    title: 'Duelscore: {{score}}',
    readyToDuel: 'Klaar om te duelleren?',
    roundsOverview: 'Overzicht van de duelrondes',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Vorige pagina',
      doneText: 'Je hebt een brainsnack gelezen',
      browseNext: 'Volgende pagina',
      challengingText: 'Lees deze informatie',
      completedText: 'Onthoud deze informatie',
    },
    brainItem: {
      correct: 'Juist',
      incorrect: 'Onjuist',
      almostCorrect: 'Bijna goed',
      completed: 'Je hebt een vraag voltooid.',
      doneText: 'Voltooid',
      openItem: 'Start',
      closeItem: 'Sluiten',
      topics: 'Topics',
      nextItem: 'Oefen verder',
      nextOnboardingItem: 'Ga verder',
      showProgress: 'Bekijk je voortgang',
    },
    poll: {
      questionTitle: 'Poll',
      resultinfo: 'Dit hebben anderen geantwoord',
      doneText: 'Je hebt een pollvraag voltooid.',
      helpText: 'Kies één antwoord',
      challengingText: 'Geef je mening',
      completedText: 'Je hebt jouw mening gegeven.',
    },
    order: {
      questionTitle: 'Volgorde',
      helpText: 'Sleep de antwoorden op volgorde.',
      correct: '{{num}} van de {{max}} juist',
      challengingText: 'Zet in de juiste volgorde',
      completedText: 'Je hebt deze vraag al beantwoord',
      pollSubmittedFeedback: 'Je antwoord is opgeslagen',
    },
    match: {
      questionTitle: 'Vraag',
      helpText: 'Sleep de antwoorden naar de goede plek.',
      correct: '{{num}} van de {{max}} juist',
      showCorrectAnswer: 'Toon juiste antwoord',
      showingCorrectAnswer: 'Bekijk de juiste antwoorden.',
      showMyAnswer: 'Toon mijn antwoord',
      challengingText: 'Verdeel de antwoorden',
      completedText: 'Je hebt deze vraag al beantwoord',
      pollSubmittedFeedback: 'Je antwoord is opgeslagen',
    },
    slider: {
      doneText: 'Done',
      questionTitle: 'Slider',
      helpText: 'Sleep de slider.',
      challengingText: 'Maak een schatting',
      completedText: 'Je hebt deze vraag al beantwoord',
      averageAnswer: 'Gemiddelde antwoord',
    },
    notification: {
      doneText: 'Notificatie gelezen.',
      title: 'Notificatie',
      pauze: 'Kan ik verder leren?',
      continueanyway: 'Toch doorgaan',
      continueLearningText:
        'Je bent extra vragen aan het oefenen. Je krijgt hier minder score voor omdat je al up‑to‑date bent.',
      panelbuttonlabel: 'Stoppen met oefenen',
    },
    multipleselect: {
      correct: '{{num}} van de {{max}} juist',
      incorrect: ', {{incorrect}} onjuist',
      doneText: 'Done',
      questionTitle: 'Vraag',
      helpText: 'Kies één of meer antwoorden',
      challengingText: 'Beantwoord de vraag',
      completedText: 'Je hebt deze vraag al beantwoord',
    },
    multiplechoice: {
      doneText: 'Done',
      questionTitle: 'Vraag',
      helpText: 'Kies één antwoord',
      challengingText: 'Beantwoord de vraag',
      completedText: 'Je hebt deze vraag al beantwoord',
    },
    a11y: {
      answerOption: {
        result: {
          loading: 'Resultaat berekenen.',
          correct: 'Deze beantwoordde optie is correct.',
          incorrect: 'Deze beantwoordde optie is incorrect.',
          wouldHaveBeenCorrect: 'Deze optie was correct geweest.',
          showingCorrect: 'Deze optie toont zich in de juiste positie.',
          submitted: 'Deze optie is ingediend.',
          pollPercentage:
            '{{percentage}}% van alle respondenten kozen deze optie.',
        },
      },
      slider: {
        result: {
          loading: 'Loading result.',
          correct: 'Deze ingediende waarde is correct.',
          incorrect: 'Deze ingediende waarde is incorrect.',
          wouldHaveBeenCorrect: 'Deze waarde was correct geweest.',
          submitted: 'Deze waarde is ingediend.',
          pollAverage: 'Dit is de gemiddelde waarde',
        },
      },
    },
    duelItem: {
      duel: 'Duel',
      draw: 'Remise',
      invite: 'Duel uitdaging',
      acceptDuel: 'Duel accepteren',
      declineDuel: 'Duel weigeren',
      yourTurn: 'Jouw beurt',
      opponentTurn: 'Tegenstanders beurt',
      youWon: 'Gewonnen!',
      youLost: 'Verloren',
      cancelled: 'Het duel is geannuleerd',
      closeItem: 'Sluiten',
      showDetails: 'Details bekijken',
      playItem: 'Speel ronde {{roundnumber}}',
      roundNumber: 'Ronde {{number}}',
      duelAccepted: 'Je hebt een duelverzoek van {{name}} geaccepteerd.',
      duelDeclined: 'Je hebt een duelverzoek van {{name}} geweigerd.',
      duelStarted: 'Het duel tegen {{name}} is gestart. Succes!',
      duelRequest: 'Nieuwe dueluitdaging!',
      duelRequestText: '{{name}} daagt je uit voor een duel',
      duelInfoInvitation: 'Je bent uitgedaagd voor een duel.',
      duelInfoMain:
        'Een korte kennisbattle tegen een collega in 5 rondes. Je wint een ronde als je de vraag sneller dan je collega goed beantwoordt. Win je meer rondes dan je tegenstander, dan win je het duel en stijg je in de ranglijst. Let op: speel elke ronde binnen 72 uur!',
      viewInvitation: 'Bekijk uitnodiging',
      versusLabel: 'VS',
      started: 'Fight!',
      doneText: 'Duel afgerond',
      tooLate: 'Te laat!',
      tooLateTurn: 'Te laat',
      practiceWhileWaiting: 'Oefen tijdens het wachten',
      practice: 'Verder oefenen',
      yourName: '{{name}} (Jij)',
      subheading: {
        waitForOpponent: 'Wacht op je tegenstander',
        yourTurn: 'Ben je er klaar voor?',
        timeOutOpponent: 'Je tegenstander wachtte te lang',
        timeOutPlayer: 'Je wachtte te lang met spelen',
        timeOutBoth: 'Jullie hebben beiden te lang gewacht',
        wellDone: 'Goed gedaan, ga zo door!',
        betterNextTime: 'Hopelijk volgende keer beter!',
      },
    },
    topicPin: {
      title: 'Onderwerp vastpinnen',
      pinenabled:
        'Je hebt {{- topiclabel}} vastgepind en krijgt hierdoor alleen items rond dit onderwerp. De items van dit onderwerp worden vaker herhaald zodat je ze kan oefenen. Druk nogmaals op het vastgepinde onderwerp om ook weer vragen van andere onderwerpen te krijgen.',
      pindisabled:
        'Het onderwerp is niet langer vastgepind. Vanaf nu krijg je weer items uit alle onderwerpen.',
      continue: 'Ga door',
      dontshowagain: 'Niet meer tonen',
      selectTopics: 'Onderwerpen selecteren',
      selectTopicsPractice: 'Selecteer de onderwerpen die je wilt oefenen.',
    },
    topicInfoHeader: 'Onderwerpen',
    topicInfo: {
      a11y: {
        buttonPurposeOpen: 'Toon onderwerp informatie',
        buttonPurposeClose: 'Verberg onderwerp informatie',
      },
    },
    challengingTextStarted: 'Ga verder waar je gebleven was',
    closeStreamItem: {
      a11y: {
        linkPurpose: 'Oefensessie afsluiten. Keer terug naar keuzepagina.',
      },
    },
  },
  login: {
    succesful: 'Je bent succesvol ingelogd.',
  },
  menu: {
    logo: '{{appTitle}} logo.',
    linkPurpose: 'Ga naar {{linkTarget}}',
    menu: 'Menu',
    duel: 'Duelleren',
    duels: 'Duels',
    duelsNotAvailable: 'Duels zijn voor jou nog niet actief.',
    duelsNotAvailableExplanation:
      'Wil je wel duels met collega’s spelen? Vraag dit aan bij je {{appName}} beheerder.',
    duelsNotEnabled:
      'Wil je wel duels met collega’s spelen? Zet de mogelijkheid om te duelleren hieronder aan.',
    duelsNotEnabledDisclaimer: "Je collega's kunnen je foto en naam zien.",
    enableDuelling: "Zet duels met collega's aan!",
    newDuel: 'Nieuw duel',
    activeDuels: 'Duels',
    duelVersus: 'VS',
    ranking: 'Ranglijst',
    completedDuels: 'Afgerond',
    rating: 'Duel rating',
    profile: 'Profiel',
    autoDuelLabel: 'Automatisch duelleren',
    anonymousDuelLabel: 'Verberg mijn naam tijdens het duelleren',
    duellingEnabledLabel: 'Meedoen aan duels',
    'help-and-settings': 'Help & Instellingen',
    challengeplayer: 'Collega uitdagen',
    pickplayer: 'Kies een collega',
    randomplayer: 'Willekeurige tegenstander',
    select: 'Onderwerpen',
    settings: 'Instellingen',
    practice: 'Oefenen',
    info: 'Info',
    duelWaiting: 'Tegenstander wordt gezocht...',
    quickDuelFailed: 'Je hebt al uitnodigingen uitstaan',
    aboutDuels: {
      heading: 'Wat is een dueluitdaging?',
      explanation1: 'Je bent uitgedaagd voor een duel.',
      explanation2:
        'Een duel is een korte kennisbattle tegen een collega in 5 rondes. Je wint een ronde als je de vraag sneller dan je collega goed beantwoordt. Win je meer rondes dan je tegenstander, dan win je het duel en stijg je in de ranglijst.',
      attention: 'Let op:',
      explanation3: 'Speel elke ronde binnen 7 dagen!',
    },
    leaveAlert: {
      title: 'Weet je het zeker?',
      text: '<p>Weet je zeker dat je wilt stoppen met oefenen?</p><p>Je score tot nu toe is opgeslagen.</p>',
      confirm: 'Stoppen',
      continue: 'Doorgaan met oefenen',
    },
    onboarding: 'overzicht',
    timeline: 'tijdlijn',
  },
  ranking: {
    title: 'Duelranking',
    subtitle:
      "Deze rating geeft aan hoe goed je bent in duelleren ten opzichte van je collega's.",
    yourPosition: 'Jouw positie ({{rank}})',
    topPositions: 'Topposities',
    loadMore: 'Meer laden',
    empty: 'Er is nog geen ranglijst. Start een duel!',
    notRanked: 'Rond een duel af en kom in de ranglijst!',
  },
  searchFeedback: {
    title: 'Zoek collega',
    oneResult: 'Er is {{amount}} resultaat gevonden.',
    searchMessage: 'Er zijn {{amount}} resultaten gevonden.',
    overflowMessage: 'Alleen de {{amount}} beste resultaten worden getoond.',
  },
  searchDuel: {
    title: 'Zoek een tegenstander',
    rankingposition: 'Positie in ranglijst {{rank}}',
    noduelpossible: 'Er is geen duel mogelijk',
    duelstillactive: 'Er is nog een duel actief',
    invitationsentondate: 'Uitnodiging verstuurd op {{date}}',
    searchPlaceholder: 'Zoek op naam',
    emptyList: 'Er is helaas niemand beschikbaar. Probeer het later nog eens.',
    emptyListSearch: 'Er zijn geen zoekresultaten.',
    randomOpponent:
      'We zoeken naar een tegenstander. Je kunt het scherm sluiten, we laten je weten als je kunt starten met het duel.',
  },
  activeDuels: {
    title: 'Speel een beschikbaar duel.',
    subtitle: '{{num}}/{{max}} duels zijn actief',
    emptyListNew: 'Er is nog geen duel. Daag een collega uit!',
    emptyListCompleted:
      'Je hebt alle duels afgerond, goed gedaan! Neem maar een kopje koffie als beloning.',
    status: {
      draw: 'Remise',
      expired: 'Duel verlopen',
      lost: 'Verloren',
      invited: 'Uitgenodigd',
      waiting: 'Wachten',
      won: 'Gewonnen',
      yourturn: 'Spelen',
    },
  },
  completedDuels: {
    title: 'Bekijk je afgeronde duels.',
    empty: 'Je hebt nog geen afgeronde duels. Rond eerst een duel af!',
  },
  error: {
    internetdown: 'Geen verbinding met het internet!',
    maintenance: {
      title: 'Onderhoud',
      subtitle:
        'De PowerApp krijgt een onderhoudsbeurt. We zijn over enkele minuten weer terug.',
    },
    versionConflict: {
      title: 'Nieuwe versie',
      text: 'Er is een nieuwe versie van de applicatie beschikbaar. Om ervoor te zorgen dat iedereen de nieuwste versie schakelen we je nu over naar de nieuwste versie.',
      refresh: 'Ga naar de nieuwste versie',
    },
    refreshButton: 'Klik om te verversen',
    internetup: 'Verbonden met het internet.',
    timeoutajax:
      'Er is een fout met de verbinding. Druk op ok om de  pagina te verversen.',
    404: 'Server niet gevonden (HTTP 404).',
    500: 'Interne server error (HTTP 500).',
    unknown: 'Onbekende fout.',
    tryReconnect: 'Bezig verbinding te zoeken...',
    alreadyAnswered: 'Dit item heeft u al beantwoord.',
    serverError:
      'Er is een fout opgetreden tijdens het contact met de server. Klik hieronder om het opnieuw te proberen, of start de app opnieuw als het probleem zich blijft voordoen.',
    retry: 'Opnieuw proberen',
    unavailableOffline: {
      title: 'Geen verbinding',
      subtitle: 'Er is momenteel geen internetverbinding',
      explanation:
        'Om de app te gebruiken is verbinding met het internet nodig. Op dit moment heb je geen verbinding. Kijk of je toegang kan krijgen tot het internet, of probeer het later opnieuw.',
    },
  },
  common: {
    CheckAnswer: 'Check mijn antwoord',
    CheckAnswers: 'Check mijn antwoorden',
    Emailaddress: 'E-mailadres',
    Name: 'Naam',
    Checking: 'Controleren...',
    Answer: 'Sla mijn antwoord op',
    Answers: 'Sla mijn antwoorden op',
    Close: 'Sluiten',
    browsePrevious: 'Vorige',
    browseNext: 'Volgende',
    Accept: 'Accepteren',
    'Log out': 'Uitloggen',
    Help: 'Help',
    Hello: 'Hoi',
    Correct: 'Correct',
    Incorrect: 'Incorrect',
    CorrectNotChosen: 'Was goed geweest',
    Round: 'Ronde',
    Version: 'Versie',
    selected: 'geselecteerd',
    'No Rating': 'Geen rating',
    'No Rank': 'Geen ranking',
    NoUserSelection: 'Er zijn geen gebruikers gevonden',
    monthNames: 'Jan Feb Mar Apr Mei Juni Juli Aug Sep Okt Nov Dec',
    or: 'of',
    on: 'aan',
    off: 'uit',
    back: 'Terug',
    matchQuestions: {
      emptyCategoryPlaceholderText: 'Versleep een antwoord hier',
      itemDropCanceled: 'Item verslepen geannuleerd',
      notOverADraggableArea: 'Je bent niet boven een versleepbaar gebied',
      itemLiftedInPositionX:
        'Je hebt het item opgetild in positie {{sourceIndex}}',
      itemMovedToPositionX:
        'Je hebt het item verplaatst naar positie {{destinationIndex}}',
      itemMovedToOtherDroppableArea:
        'Je hebt het item verplaatst van positie {{sourceIndex}} in lijst {{sourceArea}} naar lijst {{destinationArea}} in positie {{destinationIndex}}',
      startingDroppableAreaName: 'stapel met antwoorden',
    },
  },
  profile: {
    a11y: {
      selectLanguage: 'Kies taal. De huidige taal is {{lang}}.',
      selectLanguageListTitle: 'Lijst met talen',
      avatar: {
        altText: 'Profielfoto van {{avatarName}}',
        buttonPurpose: 'Wijzig profielfoto.',
      },
    },
    title: 'Profiel',
    subtitle: 'Pas hier je profiel aan.',
    pictureEdit: 'Bekijk of bewerk je foto.',
    'file-submit': 'Uploaden',
    'picture-submit': 'Deze uitsnede gebruiken',
    pictureCancel: 'Annuleren',
    pictureConfirmDelete:
      'Weet je zeker dat je je profielfoto wilt verwijderen?',
    'picture-nofileselected': 'Je hebt geen bestand geselecteerd.',
    'picture-nofile': 'Je hebt geen bestand geupload.',
    'picture-invalidsize':
      'Deze afbeelding is te groot. De maximale hoogte of breedte mag niet meer zijn dan 2600 pixels',
    'picture-notimage': 'Dit bestand is geen afbeelding.',
    passwordEdit: 'Wachtwoord wijzigen',
    'picture-saved': 'Je profielfoto is aangepast.',
    passwordTitle: 'Wachtwoord aanpassen',
    passwordText:
      'Pas je wachtwoord aan door eerst je huidige, en daarna twee keer je nieuwe wachtwoord in te voeren.',
    'password-old': 'Huidig wachtwoord',
    'password-new': 'Nieuw wachtwoord',
    'password-repeat': 'Herhaal wachtwoord',
    'password-submit': 'Nieuw wachtwoord opslaan',
    'password-oldpasswordmismatch': 'Je huidige wachtwoord klopt niet.',
    'password-newpasswordmismatch':
      'Het herhaalde wachtwoord komt niet overeen.',
    'password-passwordtooshort':
      'Het nieuwe wachtwoord moet minimaal 6 tekens lang zijn.',
    'password-passwordsaved': 'Je wachtwoord is opgeslagen!',
    profilePictureTitle: 'Profielfoto',
    profilePictureText: "Deze foto is ook zichtbaar voor je collega's.",
    generalDataTitle: 'Persoonlijke gegevens',
    generalDataText: 'Niet alle persoonlijke gegevens kun je zelf wijzigen.',
    profilePageTitle: 'Pas hier je profiel aan.',
    noEditDataText: 'Deze gegevens kun je niet zelf wijzigen.',
    profileLanguagePreference: 'Taalvoorkeur',
    profileLanguageChoice: 'Taalkeuze',
    notificationsTitle: 'Notificaties',
    emailNotifications: 'E-mail notificaties',
    pushNotifications: 'Push notificaties',
  },
  auth: {
    header: {
      login: 'Aanmelden',
      expired: 'Link verlopen',
      remind: 'Wachtwoord vergeten?',
      create: 'Activeer je account',
      reset: 'Nieuw wachtwoord',
      welcome: 'Welkom!',
    },
    field: {
      email: 'E-mailadres',
      password: 'Wachtwoord',
      oldPassword: 'Huidig wachtwoord',
      newPassword: 'Nieuw wachtwoord',
      newRepeatPassword: 'Herhaal nieuw wachtwoord',
      acceptPrivacyPolicy: 'Ik accepteer het <2>privacybeleid</2>. ',
      viewPrivacyPolicy: 'Bekijk het privacybeleid.',
      rememberMe: 'Onthoud mijn gegevens',
    },
    action: {
      itemcompleted: 'Je hebt een item afgerond',
      updateprofile: 'Profile bijwerken',
      sendLink: 'Verstuur wachtwoord reset e-mail',
      backToLogin: 'Ik wil inloggen',
      sendActivateLink: 'Activeer account',
      alreadyActivatedLogin: 'Ik heb al geactiveerd en wil inloggen',
      savePassword: 'Sla mijn nieuwe wachtwoord op',
      newPassword: 'Je nieuwe wachtwoord',
      repeatNewPassword: 'Herhaal je nieuwe wachtwoord',
      newPasswordPolicyIntro: 'Wachtwoordsterkte:',
    },
    selfActivate: {
      header: 'Activeer je account',
    },
    logintext: 'Aanmelden',
    remindText:
      'Geen probleem. We sturen je een e-mail waarmee je een nieuw wachtwoord kan instellen.',
    resetText: 'Voer je nieuwe wachtwoord in.',
    createText:
      'Welkom! Maak een nieuw wachtwoord aan. Daarna ben je klaar om in te loggen.',
    passwords: {
      user: 'Er is geen gebruiker bekend met deze e-mail-wachtwoord combinatie.',
      token: 'Deze wachtwoord-reset link is niet (meer) geldig.',
      sent: 'Een e-mail met de wachtwoord-reset link is naar het opgegeven e-mail adres verstuurd!',
      reset:
        'Je wachtwoord is aangepast. Voer je wachtwoord nogmaals in om in te loggen.',
      forgot: 'Ik weet mijn wachtwoord niet',
      passwordExpiredTitle: 'Wachtwoord verlopen',
      submitPasswordExpiredForm: 'Wachtwoord opslaan',
      passwordExpiredText:
        'Je wachtwoord is meer dan {{days}} dagen oud. Gebruik het onderstaande formulier om een nieuw wachtwoord in te stellen. Daarna kan je weer verder leren in de PowerApp',
      passwordCompleteSsoTitle: 'Kies een wachtwoord',
      passwordCompleteSsoText:
        'Stel een wachtwoord in voor je {{app}}-account. Je zult deze nodig hebben om in te loggen wanneer inloggen via {{idpText}} niet beschikbaar is.',
    },
  },
  faq: {
    a11y: {
      questionLabel: 'Vraag',
      answerLabel: 'Antwoord',
      externalLinkLabel: 'Naar de veelgestelde vragen op de PowerApp website',
    },
    header: 'Veelgestelde vragen',
    q1: 'De PowerApp is een micro-learning app, wat is dat?',
    a1: 'Vaker korter herhalend leren, dan onthoud je meer.',
    q2: 'Waarom is mijn score steeds lager dan verwacht?',
    a2: 'Je score loopt terug omdat je ook weer kennis vergeet.',
    q3: 'Wanneer loopt mijn score terug?',
    a3: 'Als je vragen fout beantwoordt of niet actief bent.',
    q4: 'Waarom herhaalt alles zo vaak?',
    a4: 'Dat is de meest effectieve manier om te leren. ',
    q5: 'Wat is een duel?',
    a5: 'Een kennisquiz met 5 vragen tegen je collega. ',
    q6: 'Waarom moet ik steeds op het duel wachten?',
    a6: 'Je collega is aan de beurt daar wacht je op.',
    q7: 'Waar vind ik nog meer informatie en vragen?',
    a7: 'De knop hieronder brengt je naar onze website. ',
    toTheFaq: 'Naar de FAQ',
    toTheWebsite: 'Naar de website',
    onBoarding: {
      q1: 'Waar gebruik ik de OnFire app voor?',
      a1: 'Als handige verzamelplek van alle informatie en taken.',
      q2: 'Waarom moet ik wachten op nieuwe informatie?',
      a2: 'Niet alles is nu relevant, zo overweldigen we je niet.',
      q3: 'Waar vind ik nog meer informatie?',
      a3: 'De knop hieronder brengt je naar onze website.',
    },
  },
}

export default translations
