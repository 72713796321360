import { faBrain } from '@fortawesome/pro-regular-svg-icons/faBrain'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog'
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt'
import { faTrophy } from '@fortawesome/pro-regular-svg-icons/faTrophy'
import { faBullseye } from '@fortawesome/pro-regular-svg-icons/faBullseye'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { Link, useMatch } from 'react-router-dom'
import { graphql } from 'relay-runtime'
import fullColorOnfireLogo from '../../../../../images/logo/full-color-onfire.svg'
import fullColorPowerappLogo from '../../../../../images/logo/full-color.svg'
import whiteOnfireLogo from '../../../../../images/logo/white-color-onfire.png'
import whitePowerappLogo from '../../../../../images/logo/white.svg'
import { useEnvironment } from '../../App'
import { NavigationBar_user$key } from '../../generated/NavigationBar_user.graphql'

import { useStores } from '../../stores'
import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import styles from './NavigationBar.scss'

interface NavigationBarLinkProps {
  onClick?: (event: SyntheticEvent) => void
  to: string
}

function NavigationBarLink(
  props: PropsWithChildren<NavigationBarLinkProps>
): ReactElement {
  const match = useMatch({
    path: props.to,
  })

  const onKeyPress = useEnterKeyHandler(props.onClick)
  return (
    <Link
      className={classNames(styles.menuItem, {
        [styles.active]: match !== null,
      })}
      onClick={props.onClick}
      onKeyPress={onKeyPress}
      tabIndex={0}
      to={Array.isArray(props.to) ? props.to[0] : props.to}
    >
      {props.children}
    </Link>
  )
}

interface NavigationBarProps {
  user: NavigationBar_user$key
}

export function Navigationbar(props: NavigationBarProps): ReactElement {
  const { t } = useTranslation()
  const { commonStore } = useStores()
  const {
    appLogos: { fullColorLogoUrl, whiteLogoUrl },
    pageTitle,
  } = useEnvironment()

  const navBarBackgroundColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--navbar-bg')
  const lightness = useMemo(() => {
    const hex =
      navBarBackgroundColor.length === 7
        ? navBarBackgroundColor.substr(1)
        : `${navBarBackgroundColor[1].repeat(
            2
          )}${navBarBackgroundColor[2].repeat(
            2
          )}${navBarBackgroundColor[3].repeat(2)}`

    // https://stackoverflow.com/a/596243
    return (
      (parseInt(hex.substr(0, 2), 16) / 255) * 0.2126 +
      (parseInt(hex.substr(2, 2), 16) / 255) * 0.7152 +
      (parseInt(hex.substr(4, 2), 16) / 255) * 0.0722
    )
  }, [navBarBackgroundColor])

  const user = useFragment(
    graphql`
      fragment NavigationBar_user on AuthenticatedUser {
        hasDuellingAvailable
        hasDuellingEnabled
        isOnboarding
      }
    `,
    props.user
  )
  const onDuelsClicked = useCallback(
    (event: SyntheticEvent): void => {
      // If duelling is not available or enabled for the user, cancel the navigation and
      // show a popup instead.
      if (!user.hasDuellingAvailable || !user.hasDuellingEnabled) {
        event.preventDefault()

        commonStore.openPopup({ type: 'duelling-not-available' })
      }
    },
    [user, commonStore]
  )
  const onInfoClicked = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      commonStore.openPopup({ type: 'repetition-state-info' })
    },
    [commonStore]
  )

  return (
    <nav className={styles.navigationBar}>
      <div className={styles.content}>
        {user.isOnboarding ? (
          <>
            <img
              alt={pageTitle}
              className={styles.logo}
              src={
                fullColorLogoUrl && whiteLogoUrl
                  ? lightness >= 0.5
                    ? fullColorLogoUrl
                    : whiteLogoUrl
                  : fullColorLogoUrl ??
                    whiteLogoUrl ??
                    (lightness >= 0.5 ? fullColorOnfireLogo : whiteOnfireLogo)
              }
            />

            <NavigationBarLink to='/'>
              <FontAwesomeIcon icon={faListAlt} />
              <span className={styles.menuItemLabel}>
                {t('menu.onboarding')}
              </span>
            </NavigationBarLink>
            <NavigationBarLink to='/onboarding'>
              <FontAwesomeIcon icon={faClock} />
              <span className={styles.menuItemLabel}>{t('menu.timeline')}</span>
            </NavigationBarLink>
          </>
        ) : (
          <>
            <Link to='/' className={styles.logo}>
              <img
                alt={
                  t('menu.logo', { appTitle: pageTitle }) +
                  ' ' +
                  t('menu.linkPurpose', { linkTarget: t('menu.practice') })
                }
                className={styles.logo}
                src={
                  fullColorLogoUrl && whiteLogoUrl
                    ? lightness >= 0.5
                      ? fullColorLogoUrl
                      : whiteLogoUrl
                    : fullColorLogoUrl ??
                      whiteLogoUrl ??
                      (lightness >= 0.5
                        ? fullColorPowerappLogo
                        : whitePowerappLogo)
                }
              />
            </Link>

            <NavigationBarLink to='/'>
              <FontAwesomeIcon icon={faBrain} />
              <span className={styles.menuItemLabel}>{t('menu.practice')}</span>
            </NavigationBarLink>
            <NavigationBarLink to='/duels' onClick={onDuelsClicked}>
              <FontAwesomeIcon icon={faTrophy} />
              <span className={styles.menuItemLabel}>{t('menu.duel')}</span>
            </NavigationBarLink>
          </>
        )}

        <NavigationBarLink to='/settings'>
          <FontAwesomeIcon icon={faCog} />
          <span className={styles.menuItemLabel}>{t('menu.settings')}</span>
        </NavigationBarLink>
        {!user.isOnboarding && (
          <NavigationBarLink to='#' onClick={onInfoClicked}>
            <FontAwesomeIcon icon={faBullseye} />
            <span className={styles.menuItemLabel}>{t('menu.info')}</span>
          </NavigationBarLink>
        )}
      </div>
    </nav>
  )
}
