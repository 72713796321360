import { ReactElement, useCallback } from 'react'
import { PropsWithChildren } from 'react'

import { classNames } from '../../utils/classNames'

import styles from './RepetitionStateIndicator.scss'
import { useTranslation } from 'react-i18next'

export const REPETITION_STATE = {
  COMPLETE: 'COMPLETE',
  SUFFICIENT: 'SUFFICIENT',
  WARNING: 'WARNING',
  ALERT: 'ALERT',
} as const

const IDLE_COLOR = '#deedef'

interface RepetitionStateIndicatorProps {
  repetitionState: keyof typeof REPETITION_STATE
  className?: string
  size: number
  small?: boolean
}

export function RepetitionStateIndicator(
  props: PropsWithChildren<RepetitionStateIndicatorProps>
): ReactElement {
  const { t } = useTranslation()
  const getGradientUrl = useCallback((repetitionState) => {
    let gradient
    switch (repetitionState) {
      case REPETITION_STATE.ALERT:
        gradient = 'url(#g-alert)'
        break
      case REPETITION_STATE.WARNING:
        gradient = 'url(#g-warning)'
        break
      default:
        gradient = 'url(#g-uptodate)'
    }
    return gradient
  }, [])

  const getStatusMessage = useCallback(
    (repetitionState) => {
      let status
      switch (repetitionState) {
        case REPETITION_STATE.ALERT:
          status = t(
            'learn.repetitionState.practiceIndicator.a11y.statusColor.red'
          )
          break
        case REPETITION_STATE.WARNING:
          status = t(
            'learn.repetitionState.practiceIndicator.a11y.statusColor.orange'
          )
          break
        default:
          status = t(
            'learn.repetitionState.practiceIndicator.a11y.statusColor.green'
          )
      }
      return t('learn.repetitionState.practiceIndicator.a11y.statusMessage', {
        statusColor: status,
      })
    },
    [t]
  )

  return (
    <div
      className={classNames(styles.repetitionstateIndicator, props.className)}
      style={{
        width: props.size + 'px',
        height: props.size + 'px',
      }}
    >
      <span id='repetitionStatus' aria-hidden={true} className='sr-only'>
        {getStatusMessage(props.repetitionState)}
      </span>
      {props.small && <div className={classNames(styles.dropshadowSmall)} />}
      <svg
        role='img'
        aria-labelledby={
          'repetitionStateIndicatorTitle' + props.repetitionState
        }
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        height='100%'
        viewBox='0 0 112 112'
      >
        <title id={'repetitionStateIndicatorTitle' + props.repetitionState}>
          {t('learn.repetitionState.practiceIndicator.a11y.title') +
            ' ' +
            getStatusMessage(props.repetitionState)}
        </title>
        <defs>
          <linearGradient
            id='g-uptodate'
            x1='0.5'
            x2='0.5'
            y2='1'
            gradientUnits='objectBoundingBox'
          >
            <stop offset='0' stopColor='#6eeb83' />
            <stop offset='1' stopColor='#1bc5bd' />
          </linearGradient>
          <linearGradient
            id='g-warning'
            x1='0.5'
            x2='0.5'
            y2='1'
            gradientUnits='objectBoundingBox'
          >
            <stop offset='0' stopColor='#ffe798' />
            <stop offset='1' stopColor='#ffa980' />
          </linearGradient>
          <linearGradient
            id='g-alert'
            x1='0.5'
            x2='0.5'
            y2='1'
            gradientUnits='objectBoundingBox'
          >
            <stop offset='0' stopColor='#ff5648' />
            <stop offset='1' stopColor='#a00c00' />
          </linearGradient>
        </defs>
        <g id='repetitionstate-indicator' data-name='repetitionstate-indicator'>
          <path
            id='Backdrop'
            d='M56,0A56,56,0,1,1,0,56,56,56,0,0,1,56,0Z'
            className={classNames([
              styles.outerCircle,
              props.small ? styles.small : '',
            ])}
          />
          <circle
            cx='50'
            cy='50'
            r='44'
            fill='#fff'
            transform='translate(6 6)'
          />
          <path
            id='outer-state-ring'
            d='M44,6.624A37.376,37.376,0,1,0,81.376,44,37.419,37.419,0,0,0,44,6.624M44,0A44,44,0,1,1,0,44,44,44,0,0,1,44,0Z'
            transform='translate(12 12)'
            fill={
              props.small
                ? getGradientUrl(props.repetitionState)
                : props.repetitionState == REPETITION_STATE.COMPLETE ||
                  props.repetitionState == REPETITION_STATE.SUFFICIENT
                ? getGradientUrl(props.repetitionState)
                : IDLE_COLOR
            }
          />
          {!props.small && (
            <path
              id='middle-state-ring'
              data-name='White-BG'
              d='M35.294,6.588A28.706,28.706,0,1,0,64,35.294,28.738,28.738,0,0,0,35.294,6.588m0-6.588A35.294,35.294,0,1,1,0,35.294,35.294,35.294,0,0,1,35.294,0Z'
              transform='translate(20.706 20.706)'
              fill={
                props.repetitionState == REPETITION_STATE.WARNING
                  ? getGradientUrl(REPETITION_STATE.WARNING)
                  : IDLE_COLOR
              }
            />
          )}
          {!props.small && (
            <path
              id='inner-state-ring'
              data-name='White-BG'
              d='M26.588,6.53A20.058,20.058,0,1,0,46.645,26.588,20.08,20.08,0,0,0,26.588,6.53m0-6.53A26.588,26.588,0,1,1,0,26.588,26.588,26.588,0,0,1,26.588,0Z'
              transform='translate(29.412 29.412)'
              fill={
                props.repetitionState == REPETITION_STATE.ALERT
                  ? getGradientUrl(REPETITION_STATE.ALERT)
                  : IDLE_COLOR
              }
            />
          )}
        </g>
      </svg>
      <div
        className={styles.avatar}
        style={{
          width: props.size * 0.333 + 'px',
          height: props.size * 0.333 + 'px',
        }}
      >
        {props.children}
      </div>
    </div>
  )
}
